<template>
    <div class="payment-all m-scroll">
        <header-bar title="确认订单">
            <img slot="left" src="@/assets/public/icon-fanhui_fff.png" alt="back" class="header-slot" @click="$router.go(-1)">
        </header-bar>

        <div class="shopping-main" v-if="!liveShow" style="margin-top:10px">
            <!-- 有地址显示地址信息 -->
            <div class="shipping-address" v-if="addrs.id && !allSelectSelfPick" @click="show = true">
                <div class="add-left" >
                    <p>
                        <span>
                            <img src="@/assets/payment/icon_name@2x.png" alt="">
                            {{addrs.name}} {{addrs.surname}}
                        </span>
                        <span>
                            <img src="@/assets/payment/code.png" alt="">
                            {{addrs.code}}
                        </span>
                    </p>
                    <p>
                        <span>
                            <img src="@/assets/payment/icon_tel@2x.png" alt="">
                            {{addrs.phone}}
                        </span>
                    </p>
                    <p>
                        <img src="@/assets/payment/icon_dress@2x.png" alt="">
                        {{addrs.addr}},{{addrs.city_arr ? addrs.city_arr.ename: addrs.city_name}},{{addrs.province_arr.ename}},{{addrs.country_arr.ename}}
                    </p>
                </div>
                <div class="add-right">
                    <img src="@/assets/payment/icon-fanhui@2x.png" alt="">
                </div>
                <img src="@/assets/payment/7@2x.png" alt="">
            </div>

            <!-- 无地址显示新增按钮 -->
            <div class="shipping-address" v-if="!addrs.id && !allSelectSelfPick" @click="$router.push('/zh/address/edit')">
                <div class="add-left" >
                    <p style="line-height:25px;margin-top:0">
                        <span>
                            <img src="@/assets/payment/icon_name@2x.png" alt="">
                            新增地址
                        </span>
                    </p>
                </div>
                <div class="add-right">
                    <img src="@/assets/payment/icon-fanhui@2x.png" alt="">
                </div>
                <img src="@/assets/payment/7@2x.png" alt="">
            </div>
            <is-gift></is-gift>
            <div class="store-item m-padding" v-for="(item, index) in order" :key="index">
                <div class="store-title van-hairline--bottom">
                    <img src="@/assets/payment/icon_dianpu@2x.png" alt="">
                    {{item.title}} 
                    <span>(共{{item.goods_list.length}}件)</span>
                    <span class="m-mooby" v-show="item.is_presale == 1">预售</span>
                    <div class="isSelfpick" v-if="item.id == 1 && addrs.country != 507">
                        <van-switch v-model="item.mailling" size="20px" @change="openSelfBox(index, item.mailling)" inactive-color="#dcdee0" />
                        <span>自提</span>
                    </div>
                </div>
                <div class="shipping" v-show="item.mailling">
                    <div class="shipping-left">
                        自提地址：Unit 133-11121 Horseshoe Way Richmond, BC, V7A5G7 Canada
                    </div>
                </div>
                <div class="goods-list">
                    <div class="goods-item" v-for="(it, ind) in item.goods_list" :key="ind">
                        <img :src="it.sku_pic" alt="" class="goods-img">
                        <div class="goods-right">
                            <p class="van-multi-ellipsis--l2">{{it.goods_name}}</p>
                            <div class="sku">{{it.sku_title}}</div>
                            <div class="total">
                                <span>{{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{it.price}}</span>
                                ×{{it.number}}
                            </div>
                        </div>
                    </div>
                </div>
                <van-cell title="优惠券" is-link @click="handleCoupon(index)">
                    <template #right-icon>
                        <div class="coupons">
                            <span>{{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{item.coupon_type == 1 ? item.coupon_price : '0.00'}}</span>
                            <img src="@/assets/payment/price.png" alt="">
                        </div>
                        <img src="@/assets/payment/icon-fanhui@2x.png" alt="">
                    </template>
                </van-cell>

                <van-cell title="兑换码" is-link @click="handleCode(index)" v-if="item.id == 1 && item.is_presale == 0">
                    <template #right-icon>
                        <div class="couponsCode">
                            {{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{item.coupon_type == 2 ? item.coupon_price : '0.00'}}
                        </div>
                        <img src="@/assets/payment/icon-fanhui@2x.png" alt="">
                    </template>
                </van-cell>
                

                <van-cell title="价格"   :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.goods_price}`" :border="false" />
                <van-cell :title="'PST(' + (item.city_tax.pst*100).toFixed(0) + '%)'" :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.pst}`" v-if="item.pst != '0.00'" :border="false" />
                <van-cell :title="'GST(' + (item.city_tax.gst*100).toFixed(0) + '%)'" :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.gst}`" v-if="item.gst != '0.00'" :border="false" />
                <van-cell :title="'QST(' + (item.city_tax.qst*100).toFixed(0) + '%)'" :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.qst}`" v-if="item.qst != '0.00'" :border="false" />
                <van-cell :title="'HST(' + (item.city_tax.hst*100).toFixed(0) + '%)'" :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.hst}`" v-if="item.hst != '0.00'" :border="false" />
                <van-cell title="税费"    :value="`US$0.00(包税)`" v-if="addrs.country == 507" :border="false" />
                <van-cell title="邮费"    :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.mailling ? '0.00' : item.freight}`"  :border="false" />
                <van-cell title="优惠"    :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.coupon_price ? item.coupon_price : '0.00'}`" :border="false" />
                <p class="store-total">小计: {{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{item.total_price}}</p>
            </div>

            <!-- 抵扣 -->
            <div class="wallet-total" v-if="wallet && (wallet.non_withdrawal_amount - 0) > 0">
                <img src="@/assets/payment/wallet.png" alt="">
                <span>活动余额可抵 <span>{{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{wallet.non_withdrawal_amount}}</span></span>
                <img src="@/assets/payment/select.png" alt="" v-if="wallet.wallet_type == 0" @click="setWallet(1)">
                <img src="@/assets/payment/active.png" alt="" v-else @click="setWallet(0)">
            </div>
        </div>
        <div v-if="!liveShow" class="cart-tools noselect van-hairline--top">
            <div class="price">
                <p>合计：{{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{totalPrice.total_price}}</p>
                <p v-if="wallet.wallet_type == 1">已使用活动余额抵用{{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{totalPrice.wallet_deduction}}</p>
            </div>
            <div class="calc-order" @click="calcOrder">去支付</div>
        </div>
        <van-overlay :show="liveShow">
            <div class="liveNostock">
                <h3>提示</h3>
                <div class="title">{{'确认订单 产品 无库存 提示 ***产品已经被抢光啦！'}}</div>
                <div class="btns">
                    <div @click.stop="$router.replace('/live')">查看更多</div>
                    <div @click.stop="$router.replace('/zh/cart')">返回购物车</div>
                </div>
            </div>
        </van-overlay>

        <!-- 地址弹窗 -->
        <van-popup v-model="show" round position="bottom" :style="{ height: '50%' }" close-on-popstate>
            <p class="address-title">修改地址</p>
            <ul class="list">
                <transition-group name="van-slide-down">
                    <li class="item" v-for="(it) in addressData" :key="it.id">
                        <van-radio :name="it.id" v-model="addrId" @click="changeAddress(it)">
                            <div class="name-phone">
                                <div>
                                    <img src="@/assets/address/icon_name@2x.png" alt="name">
                                    <span>{{it.name}}-{{it.surname}}</span>
                                    </div>
                                    <div>
                                    <img src="@/assets/address/icon_tel@2x.png" alt="name">
                                    <span>{{it.phone}}</span>
                                </div>
                            </div>
                            <div class="addr">
                                <img src="@/assets/address/icon_dress@2x.png" alt="">
                                <p>{{it.addr}},{{it.city_arr ? it.city_arr.ename: it.city_name}},{{it.province_arr.ename}},{{it.country_arr.ename}}</p>
                            </div>
                            <div class="btns">
                              <div @click="handleEdit(it)">
                                <img
                                  src="@/assets/address/icon_bianji@2x.png"
                                  alt="edit"
                                /><span>编辑</span>
                              </div>
                            </div>
                        </van-radio>
                    </li>
                </transition-group>
                <li class="address-btn">
                    <van-button to="/zh/address/edit" type="info" block>添加新地址</van-button>
                </li>
            </ul>
        </van-popup>


        <!-- 优惠券弹窗弹窗 -->
        <van-popup v-model="couponShow" round position="bottom" :style="{ height: '50%' }" close-on-popstate >
            <p class="address-title">选择优惠券</p>
            <ul class="list">
                <van-radio-group v-model="coupon_id" direction="horizontal">
                    <transition-group name="van-slide-down"  v-if="couponList.length != 0">
                            <template v-for="(it) in couponList" >
                                <li class="item" v-if="it.show == 1" :key="it.coupon_info">
                                    <van-radio :name="it.coupon_info"  @click="changeCoupon(it.coupon_info)">
                                        <div class="name-phone">
                                            <img :src="it.pic.url" alt="coupon">
                                        </div>
                                    </van-radio>
                                </li>
                            </template>
                    </transition-group>
                </van-radio-group>
            </ul>
        </van-popup>

        <!-- 兑换弹窗 -->
        <van-popup v-model="exchangeShow" round position="bottom" :style="{ height: '35%' }" close-on-popstate >
            <p class="exchange-title">兑换码</p>
            <div class="exchange-box">
              <textarea  ref="code"   placeholder="请输入有效的兑换码信息"  v-model="code"></textarea>
            </div>
            <div class="exchange-btn">
               <van-button @click="handleExchange()"  block round color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">确认</van-button>
            </div>
        </van-popup>
        
        <SelfPick :isPickUp="openSelf" @close="colseSelfBox" @submit="reLoadOrder" />
    </div>
</template>

<script>
import HeaderBar from '@/components/zh/header-bar.vue'
import IsGift from '@/components/zh/is-gift.vue'
import { order, subOrder } from '@/api/zh/cart.js'
import { address } from '@/api/zh/mine'
import SelfPick from "./selfPick.vue"
export default {
    components: { HeaderBar, SelfPick, IsGift},
    data() {
        return {
            // 砍价商品无库存状态
            liveShow: false,
            value: '1',
            addrs: {},
            order: [],
            totalPrice: {},
            exchangeCode: '',


            // 地址弹出层
            show: false,
            addressData: [],
            addrId: '',

             // 优惠券弹出层
            couponShow: false,
            conpun_index: 0,
            coupon_id: '',
            couponList: [],

            // 兑换码弹窗
            exchangeShow: false,
            code: '',
            pickUpInfo: {
                name: '',
                surname: '',
                tel: '',
                email: ''
            },
            openSelf: false,
            orderIndex: 0,
            isSelectedSelf: false,
            wallet: {},
            allSelectSelfPick: false
        }
    },
    methods: {
        openSelfBox(index, flag) {
            // 便利判断是否全部打开自提
            this.allSelectSelfPick = true
            this.order.forEach(el => {
                if(!el.mailling) {
                    this.allSelectSelfPick = false
                }
            })

            // 打开自提弹框
            if(flag) {
                this.orderIndex = index
                if(this.isSelectedSelf) {
                    this.reLoadOrder()
                } else {
                    // console.log('打开自提弹框')
                    this.openSelf = true
                }
            } else {
                let isselect = false
                this.order.forEach(el => {
                    if(el.mailling) {
                        isselect = true
                    }
                })
                if(!isselect) {
                    this.isSelectedSelf = false
                }

                this.getOrder()
            }
        },
        colseSelfBox() {
            this.allSelectSelfPick = false
            this.openSelf = false
            this.order[this.orderIndex].mailling = false
        },
        reLoadOrder(data = this.pickUpInfo) {
            // 修改自提 重新获取订单数据
            this.pickUpInfo = data
            this.openSelf = false
            this.isSelectedSelf = true
            this.getOrder()
        },
        setWallet(flag) {
            // 是否使用钱包
            var isT = true
            this.wallet.wallet_type = flag
            this.order.forEach(item => {
                if(item.id == 1 && item.coupon_info != 0) {
                    isT = false
                    this.wallet.wallet_type = 0
                    this.$notify({ type: 'warning', message: '优惠券和钱包抵扣不能同时使用' }) 
                } 
            })
            if(isT) {
                this.getOrder()
            }
        },
        // 获取订单详情
        getOrder() {
            let params = { 
                addrId:  this.addrId,
            }
            if (this.order.length > 0) {

                let pickup = {}, coupon ={}, type ={}
                this.order.forEach(e => {
                    if(!e.mailling) {
                        pickup[e.id +''+ e.is_presale] = 0
                    }else{
                        pickup[e.id +''+ e.is_presale] = 1
                    }
                    if(e.coupon_info && e.coupon_info != '') {
                        coupon[e.id+''+ e.is_presale] = e.coupon_info
                    }else{
                        coupon[e.id+''+ e.is_presale] = 0
                    }

                    if(e.coupon_type && e.coupon_type != '') {
                        type[e.id+''+ e.is_presale] = e.coupon_type
                    }else{
                        type[e.id+''+ e.is_presale] = 1
                    }
                    if(e.is_presale == 1 || coupon[e.id+''+ e.is_presale] == 0) {
                        type[e.id+''+ e.is_presale] = 0
                    }
                })

                params.addrId = this.addrs.id
                params.isPickUp = pickup
                params.isCoupon = coupon
                params.couponType = type

                if(this.wallet.wallet_type != undefined) {
                    params.walletType = this.wallet.wallet_type
                }
            }
            this.$store.state.load = true
            order(params).then(res => {
                if(res.code == 20000) {
                    this.wallet = res.wallet_info
                    this.totalPrice = res.total_info
                    this.order = res.data
                    if(res.addrs && res.addrs.length != 0) {
                        this.addrs  = res.addrs
                        this.addrId = res.addrs.id
                    }
                    this.order.forEach(e => {
                        this.$set(e, 'mailling', (params.isPickUp && params.isPickUp[e.id +''+ e.is_presale]== 1) ? true : false)
                    })
                }else if(res.code == 500201) {
                    this.liveShow = true
                }
            }).finally( () => {
                this.$store.state.load = false
            })
        },

        calcOrder(e) {
            // 提交订单
            e.target.classList.add('calc-order-scale') 
            setTimeout(() => {
                e.target.classList.remove('calc-order-scale')
            }, 150)

            let pickup = {}, coupon ={}, type = {}, isHaveSelfPick = false
            this.order.forEach(e => {
                if(!e.mailling) {
                    pickup[e.id +''+ e.is_presale] = 0
                }else{
                    pickup[e.id +''+ e.is_presale] = 1
                    isHaveSelfPick = true
                }
                if(e.coupon_info) {
                    coupon[e.id+''+ e.is_presale] = e.coupon_info
                }else{
                    coupon[e.id+''+ e.is_presale] = 0
                }
                if(e.coupon_type && e.coupon_info != 0) {
                    type[e.id+''+ e.is_presale] = e.coupon_type
                }else{
                    type[e.id+''+ e.is_presale] = 0
                }
            })
            let shareUid   = localStorage.getItem('Personal') ? localStorage.getItem('Personal') : 0
            let params = {
                addrId: this.addrs.id,
                isPickUp: pickup,
                isCoupon: coupon,
                couponType: type,
                shareUid  : shareUid
            }

            if(this.wallet.wallet_type) {
                params.walletType = this.wallet.wallet_type
            } else {
                params.walletType = 0
            }
            if(isHaveSelfPick) {
                params.pickUpInfo = this.pickUpInfo
            }

            this.$store.state.load = true
            subOrder(params).then(res => {
                if(res) {
                    localStorage.setItem('order', JSON.stringify(this.order))
                    localStorage.setItem("order_total", JSON.stringify(this.totalPrice))
                    localStorage.setItem('order_address', JSON.stringify(this.addrs))
                    this.$store.dispatch('getCart')
                    if(this.addrs.country == 507) {
                        this.$router.replace({ path: '/zh/usPay', query: { title: res.pay_title } })
                    } else {
                        this.$router.replace({ path: '/zh/pay', query: { title: res.pay_title } })
                    }
                    
                } else {
                    let that = this
                    setTimeout(() => {
                        that.$router.push('/zh/cart')
                    },1500)
                }
            }).finally( () => {
                this.$store.state.load = false
            })
        },


        // 获取地址列表
        getAddress() {
            address().then(res => {
                if(res) {
                    this.addressData = res.data
                }
            })
        },

        // 编辑地址
        handleEdit(it) {
          window.sessionStorage.setItem("address", JSON.stringify(it));
          this.$router.push({ path: "/zh/address/edit", query: { id: it.id } });
        },

        // 改变地址
        changeAddress(it) {
            this.show   = false
            this.addrs  = it
            this.addrId = it.id
            this.getOrder()
        },


        // 点击显示优惠券弹窗
        handleCoupon(index) {
            this.conpun_index = index
            this.couponList = this.order[index].coupon_list
            this.coupon_id  = this.order[index].coupon_info
            if(this.couponList.length == 0) {
                this.$notify({ type: 'warning', message: '暂无可用优惠券' })
                return false
            }
            const timer = setTimeout(() => {
                this.couponShow = true
                clearTimeout(timer)
            }, 260);
            
        },
        // 优惠券列表点击修改优惠券
        changeCoupon(id) {
            if(this.order[this.conpun_index].coupon_info == id) {
                this.order[this.conpun_index].coupon_info = 0
                this.order[this.conpun_index].coupon_type = 0
            } else {
                if(this.wallet.wallet_type == 1) {
                    this.$notify({ type: 'warning', message: '优惠券和钱包抵扣不能同时使用' })
                } else {
                    this.order[this.conpun_index].coupon_info = id
                    this.order[this.conpun_index].coupon_type = 1
                }
            }
            this.couponShow = false
            this.getOrder()
        },

        // 显示兑换码弹窗
        handleCode(index) {
            this.conpun_index = index
            this.exchangeShow = true
        },

        // 确认兑换
        handleExchange() {
            if(this.wallet.wallet_type == 1) {
                this.$notify({ type: 'warning', message: '优惠券和钱包抵扣不能同时使用' })
                return false
            } 
            this.exchangeShow = false
            this.order[this.conpun_index].coupon_type = 2
            this.order[this.conpun_index].coupon_info = this.code
            this.getOrder()
            this.code = ''
        }
    },
    created() {
        localStorage.removeItem('isPay')
        this.getOrder()
        this.getAddress()
    }
}
</script>

<style scoped lang="less">
@import './index.less';
</style>

<style lang="less">
.payment-all{
    .van-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.41);
        z-index: 999;
        .liveNostock {
            width: 80%;
            margin: auto;
            background-color: #fff;
            height: 300px;
            border-radius: 20px;
            padding: 20px;
            h3 {
                text-align: center;
            }
            .title {
                // color: #faab0c;
                font-size: 16px;
                margin-top: 40px;
            }
            .btns {
                margin-top: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                div {
                    width: 120px;
                    height: 42px;
                    margin: 15px;
                    background: linear-gradient(315deg, rgba(240, 60, 24, 1) 0%, rgba(240, 100, 72, 1) 100%);
                    border-radius: 8px;
                    font-size: 14px;
                    text-align: center;
                    line-height: 42px;
                    font-weight: 500;
                    color: rgba(255, 255, 255, 1);
                    letter-spacing: 1px;
                    -ms-touch-action: manipulation;
                    /* IE10  */
                    touch-action: manipulation;
                    /* IE11+ */
                    transition: transform 0.15s;
                    transform: scale(1, 1)
                }
            }
        }
    }
    .step-total{width:80px;height:20px;margin-right:-10px;
        button,input{height:100%;border-radius: 0}
        input{border:1px solid rgba(230,230,230,1);background-color: #fff;margin:0}
        button{width:20px;background-color: rgba(230,230,230,1)}
    }
    .m-cat-padding{padding:0}
    .m-cat-devider{
        margin:15px 0;
        font-size:18px;
        font-weight:500;
        color:rgba(15,30,51,1);
    }
    .wallet-total{margin-top:-10px;width:100%;height:48px;background-color: #fff;
        >img{
            &:nth-of-type(1){width:20px;height:20px;margin:14px 12px}
            &:nth-of-type(2),&:nth-of-type(3){width:18px;height:18px;margin:15px 16px;float: right;}
        }
        >span{font-size: 14px;color:#666;vertical-align: 18px;
            >span{color:#ED2A24}
        }
    }
    .list {
  width: 100%;
  height: calc(100% - 50px);
  padding: 10px 16px;
  overflow-x: hidden;
  overflow-y: auto;
  /deep/ .van-radio__label {
    margin-left: 8px !important;
  }
  .item {
    margin-bottom: 12px;
    background-color: #fff;
    border-radius: 5px;
    padding: 0 12px;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 0px rgba(8, 84, 191, 0.2);
    .btns {
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: flex-end;
      padding-bottom: 2px;
      & > div {
        display: flex;
        align-items: center;
        margin-left: 14px;
        & > img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
        & > span {
          font-size: 12px;
          font-weight: 400;
          color: #000;
          display: inline-block;
          height: 18px;
          line-height: 18px;
        }
      }
    }
    .name-phone {
      width: 100%;
      height: auto;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > img {
        width: 100%;
        margin-right: 4px;
      }
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        & > span {
          font-size: 12px;
          font-weight: 400;
          color: #000;
          display: inline-block;
          height: 17px;
          line-height: 17px;
        }
      }
    }
    .addr {
      width: 100%;
      padding-left: 20px;
      padding-bottom: 0px !important;
      position: relative;
      & > img {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
      }
      & > p {
        font-size: 12px;
        font-weight: 400;
        color: #000;
        line-height: 17px;
        word-break: break-all;
      }
    }
    .edit {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btns {
        display: flex;
        align-items: center;
        & > div {
          display: flex;
          align-items: center;
          margin-left: 14px;
          & > span {
            font-size: 14px;
            font-family: Avenir-Book, Avenir;
            font-weight: normal;
            color: #0854bf;
            display: inline-block;
            height: 17px;
            line-height: 17px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
}
</style>
